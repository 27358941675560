<template>
    <div>
        <template v-if="updateMode && !listMode">
            <v-btn
                :class="isPosted != 1 ? 'ml-3' : 'ml-0'"
                :loading="loading.invoice"
                small
                @click.stop="printInvoice()"
            >
                <v-icon small>far fa-file-pdf</v-icon>
                <span class="ml-1">{{ $t('message.invoice') }}</span>
            </v-btn>
            <v-btn
                :loading="loading.deliveryOrder"
                small
                class="ml-3"
                @click.stop="printDeliveryOrder()"
            >
                <v-icon small>far fa-file-pdf</v-icon>
                <span class="ml-1">{{ $t('message.deliveryOrder') }}</span>
            </v-btn>
            <v-btn
                :loading="loading.releaseOrder"
                small
                class="ml-3"
                @click.stop="printReleaseOrder()"
            >
                <v-icon small>far fa-file-pdf</v-icon>
                <span class="ml-1">{{ $t('message.releaseOrder') }}</span>
            </v-btn>
        </template>
        <template v-if="updateMode && listMode">
            <v-list-item class="font-sm" @click.stop="printInvoice()">
                <v-progress-circular :size="20" :width="2" indeterminate color="red lighten-1" class="mr-2" v-if="loading.invoice"/>
                <v-icon color="red lighten-1" small class="mr-2" v-else>fas fa-file-pdf</v-icon>
                <span>{{ $t('message.invoice') }}</span>
            </v-list-item>
            <v-list-item class="font-sm" @click.stop="printDeliveryOrder()">
                <v-progress-circular :size="20" :width="2" indeterminate color="red lighten-1" class="mr-2" v-if="loading.deliveryOrder"/>
                <v-icon color="red lighten-1" small class="mr-2" v-else>fas fa-file-pdf</v-icon>
                <span>{{ $t('message.deliveryOrder') }}</span>
            </v-list-item>
            <v-list-item class="font-sm" @click.stop="printReleaseOrder()">
                <v-progress-circular :size="20" :width="2" indeterminate color="red lighten-1" class="mr-2" v-if="loading.releaseOrder"/>
                <v-icon color="red lighten-1" small class="mr-2" v-else>fas fa-file-pdf</v-icon>
                <span>{{ $t('message.releaseOrder') }}</span>
            </v-list-item>
        </template>
        <PrintDeliveryOrderBlob
            :local-sales-invoice-id="localSaleId"
            :key="updateDeliveryOrderKey"
            @pdf-uploading="deliveryOrderUploading"
            @pdf-uploaded="deliveryOrderUploaded"
            @pdf-not-uploaded="deliveryOrderNotUploaded"
            v-if="hasDeliveryOrder && version == 1.0"
        />
        <PrintReleaseOrderBlob
            :local-sales-invoice-id="localSaleId"
            :key="updateReleaseOrderKey"
            @pdf-uploading="releaseOrderUploading"
            @pdf-uploaded="releaseOrderUploaded"
            @pdf-not-uploaded="releaseOrderNotUploaded"
            v-if="hasReleaseOrder && version == 1.0"
        />
        <PrintLocalSalesInvoiceBlob
            :local-sales-invoice-id="localSaleId"
            :key="updateInvoiceKey"
            @pdf-uploading="invoiceUploading"
            @pdf-uploaded="invoiceUploaded"
            @pdf-not-uploaded="invoiceNotUploaded"
            v-if="hasInvoice && version == 1.0"
        />
        <PrintDeliveryOrderBlobV2
            :local-sales-invoice-id="localSaleId"
            :key="updateDeliveryOrderKey"
            @pdf-uploading="deliveryOrderUploading"
            @pdf-uploaded="deliveryOrderUploaded"
            @pdf-not-uploaded="deliveryOrderNotUploaded"
            v-if="hasDeliveryOrder && version > 1.0"
        />
        <PrintReleaseOrderBlobV2
            :local-sales-invoice-id="localSaleId"
            :key="updateReleaseOrderKey"
            @pdf-uploading="releaseOrderUploading"
            @pdf-uploaded="releaseOrderUploaded"
            @pdf-not-uploaded="releaseOrderNotUploaded"
            v-if="hasReleaseOrder && version > 1.0"
        />
        <PrintLocalSalesInvoiceBlobV2
            :local-sales-invoice-id="localSaleId"
            :key="updateInvoiceKey"
            @pdf-uploading="invoiceUploading"
            @pdf-uploaded="invoiceUploaded"
            @pdf-not-uploaded="invoiceNotUploaded"
            v-if="hasInvoice && version > 1.0"
        />
    </div>
</template>

<script>
import {mapFields} from "vuex-map-fields";
import {api} from "Api";
import {log, makeId} from "Helpers/helpers";

const PrintReleaseOrderBlob = () => import("Views/v1/localsales/PrintReleaseOrderBlob");
const PrintReleaseOrderBlobV2 = () => import("Views/v1/localsales/PrintReleaseOrderBlobV2");
const PrintDeliveryOrderBlob = () => import("Views/v1/localsales/PrintDeliveryOrderBlob");
const PrintDeliveryOrderBlobV2 = () => import("Views/v1/localsales/PrintDeliveryOrderBlobV2");
const PrintLocalSalesInvoiceBlob = () => import("Views/v1/localsales/PrintLocalSalesInvoiceBlob");
const PrintLocalSalesInvoiceBlobV2 = () => import("Views/v1/localsales/PrintLocalSalesInvoiceBlobV2");

export default {
    name: "PrintLocalSaleButtons",
    props: ['localSaleId','updateMode','listMode','isPosted','version'],
    components: {PrintLocalSalesInvoiceBlob, PrintLocalSalesInvoiceBlobV2, PrintDeliveryOrderBlob, PrintDeliveryOrderBlobV2, PrintReleaseOrderBlob, PrintReleaseOrderBlobV2},
    data() {
        return {
            currentDeliveryOrderPdf: null,
            currentInvoicePdf: null,
            currentReleaseOrderPdf: null,
            hasDeliveryOrder: false,
            hasInvoice: false,
            hasReleaseOrder: false,
            loading: {
                deliveryOrder: false,
                invoice: false,
                releaseOrder: false
            },
            updateInvoiceKey: 1,
            updateDeliveryOrderKey: 2,
            updateReleaseOrderKey: 3
        }
    },
    computed: {
        ...mapFields('runtime',{
            openedTabs: 'openedTabs'
        }),
    },
    methods: {
        getExistingPdf() {
            return new Promise((resolve, reject) => {
                api
                    .get("/localsales/" + this.localSaleId, {
                        params: {
                            fields: [
                                'localsales.location AS localsales__location',
                                'localsales.do_location AS localsales__do_location',
                                'localsales.ro_location AS localsales__ro_location'
                            ]
                        }
                    })
                    .then(response => {
                        const localSale = response.data.data[0]
                        if(localSale){
                            this.currentInvoicePdf = localSale.Localsale.location
                            this.currentDeliveryOrderPdf = localSale.Localsale.do_location
                            this.currentReleaseOrderPdf = localSale.Localsale.ro_location
                        }
                        resolve('success')
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        deliveryOrderNotUploaded() {
            this.loading.deliveryOrder = false
            this.hasDeliveryOrder = false
            this.$toast.error(this.$t('message.errors.couldNotPrint'),
                {
                    classes: ['icon-float-left'],
                    icon: 'error_outline'
                }
            )
        },
        deliveryOrderUploaded(url) {
            this.currentDeliveryOrderPdf = url
            this.loading.deliveryOrder = false
            this.hasDeliveryOrder = false
            this.viewFile(this.currentDeliveryOrderPdf)
        },
        deliveryOrderUploading() {
            this.loading.deliveryOrder = true
        },
        invoiceNotUploaded() {
            this.loading.invoice = false
            this.hasInvoice = false
            this.$toast.error(this.$t('message.errors.couldNotPrint'),
                {
                    classes: ['icon-float-left'],
                    icon: 'error_outline'
                }
            )
        },
        invoiceUploaded(url) {
            this.currentInvoicePdf = url
            this.loading.invoice = false
            this.hasInvoice = false
            this.viewFile(this.currentInvoicePdf)
        },
        invoiceUploading() {
            this.loading.invoice = true
        },
        printDeliveryOrder() {
            this.loading.deliveryOrder = true
            this.getExistingPdf()
                .then(()=>{
                    if(this.currentDeliveryOrderPdf != null){
                        this.viewFile(this.currentDeliveryOrderPdf)
                            .then(() => this.loading.deliveryOrder = false)
                            .catch(() => this.loading.deliveryOrder = false)
                    } else {
                        this.hasDeliveryOrder = true
                    }
                })
                .catch((error) => {
                    log(error)
                    this.loading.deliveryOrder = false
                    this.hasDeliveryOrder = false
                })
        },
        printInvoice() {
            this.loading.invoice = true
            this.getExistingPdf()
                .then(()=>{
                    if(this.currentInvoicePdf != null){
                        this.viewFile(this.currentInvoicePdf)
                            .then(() => this.loading.invoice = false)
                            .catch(() => this.loading.invoice = false)
                    } else {
                        this.hasInvoice = true
                    }
                })
                .catch((error) => {
                    log(error)
                    this.loading.invoice = false
                    this.hasInvoice = false
                })
        },
        printReleaseOrder() {
            this.loading.releaseOrder = true
            this.getExistingPdf()
                .then(()=>{
                    if(this.currentReleaseOrderPdf != null){
                        this.viewFile(this.currentReleaseOrderPdf)
                            .then(() => this.loading.releaseOrder = false)
                            .catch(() => this.loading.releaseOrder = false)
                    } else {
                        this.hasReleaseOrder = true
                    }
                })
                .catch((error) => {
                    log(error)
                    this.loading.releaseOrder = false
                    this.hasReleaseOrder = false
                })
        },
        releaseOrderNotUploaded() {
            this.loading.releaseOrder = false
            this.hasReleaseOrder = false
        },
        releaseOrderUploaded(url) {
            this.currentReleaseOrderPdf = url
            this.loading.releaseOrder = false
            this.hasReleaseOrder = false
            this.viewFile(this.currentReleaseOrderPdf)
        },
        releaseOrderUploading() {
            this.loading.releaseOrder = true
        },
        viewFile(file) {
            return new Promise((resolve,reject) => {
                try {
                    let tab = window.open(file + '?' + makeId(5), '_blank')
                    tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                    this.openedTabs.push(tab.name)
                    resolve('done')
                } catch(error) {
                    reject('error')
                }
            })
        }
    }
}
</script>

<style scoped>

</style>